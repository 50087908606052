@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('vars.css');
@import url('googleButton.css');

#root {
  height: 100%;
}

.large-table table > thead > tr > th:not(.ant-table-expand-icon-th) {
  min-width: 0px !important;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ql-color-accent1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ql-color-accent1-t-lighten1);
}
.headerTop + div {
  /* use strict px number to make header+ aside works together */
  height: calc(100vh - var(--ql-header-height) - var(--ql-footer-height)) !important;
}
.qlAsideLeft + div {
  overflow-y: auto;
}
/* Button */
.ant-btn-ghost {
  border-color: var(--ql-color-accent1) !important;
  border-width: 2px !important;
  color: var(--ql-color-accent1) !important;
}

/* Modal */
.ant-modal-content {
  border-radius: var(--modal-border-radius);
}
.ant-modal-header {
  border-top-left-radius: var(--modal-border-radius);
  border-top-right-radius: var(--modal-border-radius);
}
/* Table */
/* EXPERIMENTAL */
/* .ant-table-wrapper {
  width:100% !important;
} */
.ant-table-content {
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-gutter: auto;
}
.ant-table-container {
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-gutter: auto;
}
.ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
}
.ant-select-single.ant-select-allow-clear.ant-select-show-arrow:has(.ant-select-clear):hover
  > .ant-select-arrow {
  display: none;
}

.img-crop-container {
  border-radius: var(--card-radius);
}
.ant-modal-title {
  color: var(--ql-typography-text-color-primary-reverse);
}

.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 0;
}

.color-picker-popover .ant-popover-inner-content {
  padding: 0 !important;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: var(--input-disabled-bg);
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  background: var(--input-disabled-bg);
}

.color-chooser {
  width: 240px;
  border: none;
  background-color: #ffffff;
  padding: 0;
  overflow: hidden;
  border-radius: var(--card-radius);
}
.color-chooser-canvas {
  height: 150px;
}
.color-chooser-preview {
  width: 20px;
  height: 20px;
  background-image: none;
  border: none;
}
.color-chooser-readout {
  border-top: none;
  padding: 0;
  width: auto;
  margin: 10px 10px 10px 10px;
  align-items: start;
}
.color-chooser-controls {
  margin: 10px;
}

.color-chooser-preview-mask {
  border-radius: 10px;
}
.color-chooser-input-container {
  border: 1px solid var(--ql-border-color-default);
  height: fit-content;
}
.color-chooser input {
  background-color: #ffffff;
  color: var(--ql-typography-text-color-primary);
}
.color-chooser-input-prefix,
.color-chooser-input-suffix {
  display: none;
}
.color-chooser-values {
  display: flex;
  align-items: center;
}
.color-chooser button,
.color-chooser button:hover,
.color-chooser button:active,
.color-chooser button:focus {
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border: none;
  color: var(--ql-typography-text-color-primary);
}
.color-chooser-values > *:not(:last-child) {
  margin-right: 2px;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--input-disabled-bg);
}
.glowing-effect {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@keyframes glowing {
  0% {
    background-color: var(--glowingColor);
    -webkit-box-shadow: 0 0 3px var(--glowingColor);
  }
  50% {
    background-color: var(--glowingColor-fused);
    -webkit-box-shadow: 0 0 40px var(--glowingColor-fused);
  }
  100% {
    background-color: var(--glowingColor);
    -webkit-box-shadow: 0 0 3px var(--glowingColor);
  }
}

